<script setup lang="ts">
import { toast } from "vue-sonner";

const route = useRoute();
useHead({
  titleTemplate: () => {
    return route.meta.title ? `${route.meta.title} - Rise Pay` : "Rise Pay";
  },
});
const sessionStore = useSessionStore();
const clerkUserStore = useClerkUserStore();
const onboardingStore = useOnboardingStore();
const userStore = useUserStore();
const runtimeConfig = useRuntimeConfig();
sessionStore.refreshSession();
const invitationsStore = useInvitationsStore();
const permissionStore = usePermissionsStore();

const isMobileOpen = ref(false);

provide("confirm", showConfirmation);

function handleSidebarToggle(isOpen: boolean) {
  isMobileOpen.value = isOpen;
}

onMounted(async () => {
  clerkUserStore.setUser();

  if (!onboardingStore.onboarding.onboarded) {
    await onboardingStore.getOnboarding();
    if (runtimeConfig.public.isLocalDevelopment) return;
    window.location.replace(`${runtimeConfig.public.appUrl}/onboarding/`);

    return;
  }
  const notificationsStore = useNotificationsStore();
  await notificationsStore.getNotifications();

  try {
    invitationsStore.loading = true;
    userStore.loading = true;
    try {
      await invitationsStore.getNotifications({ action: "invite" });
    } catch (error) {
      console.log("Failed to get Notifations", error);
      toast.error(
        "There has been an error trying to get the Notifications, please contact support."
      );
    }
    await userStore.getMe();

    try {
      await permissionStore.getRiseIDPermissions(
        userStore.me.user?.nanoid as string
      );
    } catch (error) {
      console.log("Failed to get RiseID", error);
      toast.error(
        "There has been an error retrieving your RiseID permissions, please contact support."
      );
    }
  } finally {
    invitationsStore.loading = false;
    userStore.loading = false;
  }
});

const confirmationModal = ref(false);
const confirmationModalText = ref("");
const confirmationResolve = ref<(value: boolean) => void>();

function showConfirmation(text: string): Promise<boolean> {
  confirmationModalText.value = text;
  confirmationModal.value = true;

  return new Promise((resolve) => {
    confirmationResolve.value = resolve;
  });
}

function handleConfirm(confirmed: boolean) {
  confirmationModal.value = false;
  confirmationResolve.value?.(confirmed);
}

// Make it available globally
defineExpose({ showConfirmation });
</script>

<template>
  <div class="text-gray-900 dark:text-white">
    <WalletNotAuthorized />
    <Web3Modal />
    <ConfirmationModal
      v-model="confirmationModal"
      :text="confirmationModalText"
      @confirm="handleConfirm(true)"
      @cancel="handleConfirm(false)"
    />
    <div v-if="route.meta.blank">
      <slot />
    </div>
    <div v-else class="flex min-h-[100dvh] print:!p-0">
      <LeftMenu @on-toggle="handleSidebarToggle" />
      <div
        class="pt-24 md:pt-0 md:pl-[220px] flex-grow max-w-[100dvw]"
        :class="{ 'overflow-hidden': isMobileOpen }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
