<script setup lang="ts">
import type { Role } from "~/stores/api/relationships";

const {
  name,
  role,
  tasksNumber,
  id,
  fromOrganisation,
  avatar,
  isProfile,
  account,
} = defineProps<{
  name: string;
  account: string;
  fromOrganisation?: boolean;
  role: Role;
  tasksNumber: number;
  id: string;
  avatar?: string;
  isProfile?: boolean;
  scope?: string;
}>();

const route = useRoute();
const relationshipsStore = useRelationshipsStore();
const web3Store = useWeb3Store();
const balance = ref<number | null>(null);

const active = computed(() => {
  if (role === "user" && route.path.startsWith("/account/")) return true;

  return id === relationshipsStore.currentWorkspace?.nanoid;
});

onMounted(async () => {
  balance.value = account ? await web3Store.getBalance(account) : 0;
});
</script>

<template>
  <nuxt-link
    :to="
      role === 'user'
        ? '/account/profile'
        : `/${relationshipsStore.roleScopes[role]}/${id}/dashboard`
    "
    class="flex cursor-pointer items-center gap-3 p-0.5 h-[64px] text-sm transition mx-4"
    :class="{
      'bg-gradient-to-r from-[#37AEDD] via-[#4E60D8] via-45% to-transparent to-85% rounded-lg backdrop-blur-lg':
        active,
    }"
  >
    <div
      class="flex flex-1 items-center rounded-md h-[60px] pl-2 justify-between"
      :class="{
        'bg-opacity-90 dark:bg-opacity-80 bg-gray-100 dark:bg-black': active,
      }"
    >
      <div class="flex items-center">
        <div>
          <div
            class="rounded-full bg-white dark:bg-gray-900 h-9 w-9 border border-gray-300 dark:border-gray-900"
            :class="{
              'border-[#4E60D8]': active,
            }"
          >
            <i
              v-if="isProfile"
              class="ri-fw ri-account-pin-box-fill text-primary-500 text-2xl ml-1"
            />
            <RiseAvatar
              v-else
              size="sm"
              :image="avatar"
              type="individual"
              :name="name"
            />
          </div>
        </div>

        <div class="flex flex-col gap-1 text-base font-medium">
          <div class="flex">
            <span class="ml-4">{{
              fromOrganisation ? "Organization" : name
            }}</span>
            <div
              v-if="role && role !== 'user' && role !== 'contractor'"
              class="ml-2 flex h-[22px] items-center justify-center rounded-md px-[10px] text-xs leading-[12px]"
              :class="{
                'text-[#330350] bg-[#F3E5FC] dark:bg-[#330350] dark:text-[#C580F0]':
                  active,
                'bg-white dark:bg-[#1F2A37]': !active,
              }"
            >
              {{ relationshipsStore.roleMap[role] }}
            </div>
          </div>
          <span
            v-if="!isProfile"
            class="ml-4 text-sm font-semibold text-gray-500 dark:text-gray-400"
            >Balance: {{ $filters.currency(balance) ?? "-" }}
          </span>
        </div>
      </div>
      <div class="pr-3 flex gap-1">
        <div
          v-if="tasksNumber > 0"
          class="flex h-[16px] items-center justify-center rounded-full text-[#330350] bg-[#F3E5FC] dark:bg-[#330350] dark:text-[#C580F0] px-[6px] text-[10px] font-bold"
        >
          <span class="-mb-[1px]">{{ tasksNumber }}</span>
        </div>
        <font-awesome-icon
          v-if="active"
          icon="check"
          class="px-2 text-sm text-primary"
        />
        <i v-if="!active" class="ri-arrow-right-s-line text-xl -mb-1" />
      </div>
    </div>
  </nuxt-link>
</template>
