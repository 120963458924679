<script setup lang="ts">
defineProps<{
  text: string;
}>();
const emit = defineEmits<{
  confirm: [];
  cancel: [];
}>();
const modelValue = defineModel<boolean>({ default: false });
</script>

<template>
  <RiseModal
    v-model="modelValue"
    title="Confirmation"
    @update:modelValue="(e: boolean) => !e && emit('cancel')"
    @confirm="emit('confirm')"
  >
    <div class="">
      {{ text }}
    </div>
  </RiseModal>
</template>
