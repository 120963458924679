export function useHelpers() {
  const isScrollAtBottom = ref<boolean>(false);

  const hasOverflow = ref<boolean>(false);

  function checkScrollPosition(event: Event) {
    const target = event.target as HTMLElement;
    const { scrollTop, scrollHeight, clientHeight } = target;

    // Check if scroll reached the bottom (with a small tolerance)
    isScrollAtBottom.value =
      Math.abs(scrollHeight - scrollTop - clientHeight) < 2;
  }

  function checkHasOverflow(element: HTMLElement) {
    hasOverflow.value = element.scrollHeight > element.clientHeight;
  }

  return {
    checkScrollPosition,
    isScrollAtBottom,
    hasOverflow,
    checkHasOverflow,
  };
}
